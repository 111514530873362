@tailwind base;

@font-face {
  font-family: "IronBrine";
  src: url("../fonts/IronBrine.eot");
  src: url("../fonts/IronBrine.eot?#iefix") format("embedded-opentype"), url("../fonts/IronBrine.woff2") format("woff2"),
    url("../fonts/IronBrine.woff") format("woff"), url("../fonts/IronBrine.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: "Graphik";
  src: url("../fonts/Graphik-Regular.eot");
  src: url("../fonts/Graphik-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Graphik-Regular.woff2") format("woff2"), url("../fonts/Graphik-Regular.woff") format("woff"),
    url("../fonts/Graphik-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("../fonts/Graphik-Bold.eot");
  src: url("../fonts/Graphik-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Graphik-Bold.woff2") format("woff2"),
    url("../fonts/Graphik-Bold.woff") format("woff"), url("../fonts/Graphik-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
} */

@tailwind components;
@tailwind utilities;

html {
  font-size: 15px;
}

@screen md {
  html {
    font-size: 18px;
  }
}

[v-cloak] {
  display: none;
}

.transition {
  transition: all 0.5s ease;
}

.btn {
  background-clip: padding-box;
  @apply py-3 px-5 border-solid border-4;
  border-image-source: url("../img/button-bg.svg");
  border-image-slice: 4;
}

.btn.btn-w {
  border-image-source: url("../img/button-bg-w.svg");
}

.bg-btn {
  background-clip: padding-box;
  @apply py-1 px-1 border-solid border-4 text-white bg-main-dark;
  border-image-source: url("../img/button-small.svg");
  border-image-slice: 3;
}

.bg-btn-alt {
  @apply py-2 text-white bg-cover;
  background-image: url("../img/button-empty.svg");
}

.gold-border {
  @apply border-solid border-4;
  background-clip: padding-box;
  border-image-source: url("../img/gold-border.svg");
  border-image-slice: 3;
}

.underlined {
  background-clip: padding-box;
  @apply border-solid border-b-2;
  border-image-source: url("../img/button-bg-w.svg");
  border-image-slice: 4;
}

.hyphenated {
  -webkit-hyphenate-limit-before: 3;
  -webkit-hyphenate-limit-after: 3;
  -webkit-hyphenate-limit-chars: 6 3 3;
  -webkit-hyphenate-limit-lines: 2;
  -webkit-hyphenate-limit-last: always;
  -webkit-hyphenate-limit-zone: 8%;
  hyphens: auto;
  hyphenate-limit-chars: 6 3 3;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;
  hyphenate-limit-zone: 8%;
}

.shadowed {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.bg-white .shadowed {
  text-shadow: none;
}

.sep {
  @apply border-solid;
  border-image: url("../img/separator.svg");
  border-image-slice: 5;
}

.home > .container {
  @apply py-16;
}

.bg-white .lang-chooser {
  @apply text-main-dark;
}

@screen md {
  .home > .container {
    @apply py-32;
  }
}

/* purgecss start ignore */
.rich-text {
  @apply leading-normal;
  & > * + *,
  & li > p + p {
    @apply mt-6;
  }
  & strong {
    @apply text-black font-bold;
  }
  & a {
    @apply text-black font-semibold;
  }
  & strong a {
    @apply font-bold;
  }
  & h2 {
    @apply font-display leading-tight text-4xl;
  }
  & h3 {
    @apply font-display leading-tight text-2xl;
  }
  & h4 {
    @apply font-display leading-tight text-xl;
  }
  & ul,
  & ol {
    @apply pl-5;
    @screen sm {
      @apply pl-10;
    }
  }

  & ul {
    @apply list-disc;
  }
  & ol {
    @apply list-decimal;
  }
}

.responsive-object {
  position: relative;
}

.responsive-object iframe,
.responsive-object object,
.responsive-object embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* purgecss end ignore */
